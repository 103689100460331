import Vue from "vue";
import VueRouter from "vue-router";

const includPush = VueRouter.prototype.push;
const includReplace = VueRouter.prototype.replace;
VueRouter.prototype.push = function push(location) {
  return includPush.call(this, location).catch((err) => err);
};
VueRouter.prototype.replace = function replace(location) {
  return includReplace.call(this, location).catch((err) => err);
};
/*********************** 首页 ******************/
import Home from "@/views/home/index"; //首页
import Webmaster from "@/views/webmaster/index"; //站长推荐
import Tool from "@/views/tool/index"; //实用工具

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
  },
  {
    path: "/fl.html",
    name: "Home",
    component: Home,
  },
  {
    path: "/webmaster",
    name: "Webmaster",
    component: Webmaster,
  },
  {
    path: "/tool",
    name: "Tool",
    component: Tool,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
