<template>
  <div class="home">
    <template v-if="isMobile()">
      <div class="headerBox">
        <div class="leftBox">
          <div class="logo"></div>
          <div class="appInfo">
            <div class="title">春香阁APP下载导航</div>
            <div class="desc">永久网址：{{ url }}</div>
          </div>
        </div>
        <div class="rightBox" @click="jumpUrl('https://hls985.com')">
          <div class="icon"></div>
          <div class="name">每日吃瓜</div>
        </div>
      </div>
      <van-notice-bar color="white" :text="notice">
        <template slot="left-icon">
          <div class="noticeIcon"></div>
        </template>
      </van-notice-bar>
      <!-- <AdvSwiper
      :advList="topAdvList"
      :hasPagination="true"
      v-if="topAdvList.length"
      class="adv"
    /> -->
      <van-tabs
        class="myTabs"
        background="#09050a"
        swipeable
        :lazy-render="true"
      >
        <van-tab :title="item.name" v-for="item in tabList" :key="item.id">
          <TabItem
            :moduleId="item.id"
            :topAdvList="topAdvList"
            :bottomAdvList="bottomAdvList"
          />
        </van-tab>
      </van-tabs>
      <BottomAdvSwiper
        :advList="bottomAdvList"
        :hasPagination="true"
        v-if="bottomAdvList.length"
        class="bottomAdv adv"
      />
    </template>
    <template v-else>
      <div class="tip">本站仅支持移动设备访问，请使用移动设备访问!</div>
    </template>
  </div>
</template>
<script>
import { getTabs, visitRecord } from "@/api/home";
import TabItem from "./components/tabItem.vue";
import BottomAdvSwiper from "@/components/BottomAdvSwiper";
import mobile from "is-mobile";
// import AdvSwiper from "@/components/AdvSwiper";
export default {
  name: "home",
  components: {
    TabItem,
    BottomAdvSwiper,
    // AdvSwiper,
  },
  data() {
    return {
      tabList: [],
      topAdvList: [],
      bottomAdvList: [],
      url: "",
      emUrl: "",
      notice: "",
    };
  },
  mounted() {
    // console.log(mobile(), "=-=-");
    this.getTabs();
    setTimeout(() => {
      visitRecord();
    }, 5000);
  },
  methods: {
    isMobile() {
      return mobile();
    },
    async getTabs() {
      let res = await this.$Api(getTabs);
      if (res && res.code == 200) {
        this.tabList = res.data.module || [];
        // this.advList = res.data.adv || [];
        let advArr = res.data.adv || [];
        // this.topAdvList = [];
        // this.bottomAdvList = [];
        this.topAdvList = advArr.filter((i) => {
          return i.location == "1";
        });
        this.bottomAdvList = advArr.filter((i) => {
          return i.location == "2";
        });
        console.log(this.topAdvList);
        // advArr.forEach((item) => {
        //   if (item.location == "1") {
        //     this.topAdvList.push(item);
        //   } else if (item.location == "2") {
        //     this.bottomAdvList.push(item);
        //   }
        // });
        this.url = res.data.url;
        this.emUrl = res.data.emUrl;
        this.notice = res.data.notice;
      }
    },
    jumpUrl(url) {
      window.open(url);
    },
  },
};
</script>
<style lang="scss" scoped>
.home {
  /deep/ .van-tabs {
    .van-tabs__wrap {
      height: 50px;
      .van-tab {
        font-size: 16px;
        color: #646566;
      }
      .van-tab--active {
        color: white;
      }
      .van-tabs__line {
        height: 8px;
        width: 22px;
      }
      .van-tabs__nav--line {
        padding-bottom: 10px;
      }
    }
  }
  .headerBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(90deg, #ff8a48 0%, #ff3d57 100%);
    height: 55px;
    padding: 0 14px;
    .leftBox {
      display: flex;
      align-items: center;
      .logo {
        width: 40px;
        height: 40px;
        background: url("./../../assets/png/logo.png") no-repeat;
        background-size: 100% 100%;
      }
      .appInfo {
        margin-left: 5px;
        .title {
          font-size: 14px;
          font-weight: 800;
        }
        .desc {
          font-size: 12px;
        }
      }
    }
    .rightBox {
      display: flex;
      align-items: center;
      .icon {
        width: 20px;
        height: 20px;
        background: url("./../../assets/png/navIcon.png") no-repeat;
        background-size: 100% 100%;
        margin-right: 3px;
      }
      .name {
        font-size: 13px;
      }
    }
  }
  .noticeIcon {
    height: 20px;
    width: 20px;
    background: url("./../../assets/png/noticeIcon.png") no-repeat;
    background-size: 100% 100%;
  }
  /deep/ .van-notice-bar {
    font-size: 12px !important;
    background-image: linear-gradient(90deg, #5e010f 0%, #6e595c 100%);
    height: 30px;
  }
  .adv {
    height: 104px;
    :deep(img) {
      height: 104px;
    }
  }
  .bottomAdv {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }
}
.tip {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
