import { advClick } from "@/api/home";
import { Api, getUrlParams } from "@/utils/index";

/**
 * 跳转广告
 * @param {*} item 广告信息
 */
export async function jumpAdvs(item) {
  Api(advClick, item);
  let obj = getUrlParams(item.url);
  let cUrlObj = getUrlParams(window.location.href);

  if (Object.keys(obj).length || !Object.keys(cUrlObj).length) {
    window.open(item.url);
  } else {
    let url = item.url;
    let tempParams = [];
    for (let key in cUrlObj) {
      tempParams.push(`${key}=${cUrlObj[key]}`);
    }
    let paramsStr = tempParams.join("&");
    let jumpUrl = url + "?" + paramsStr;
    window.open(jumpUrl);
  }
}
