<template>
  <div class="tabItem">
    <div class="main" v-if="!loading">
      <NoData v-if="!hengApp.length && !shuApp.length" />
      <div class="content" v-else>
        <AdvSwiper
          :advList="topAdvList"
          :hasPagination="true"
          v-if="topAdvList.length"
          class="adv"
        />
        <div class="appBoxs">
          <div
            class="appBox"
            @click="jump(item)"
            v-for="item in hengApp"
            :key="item.id"
          >
            <ImgDecypt :key="item.id" :src="item.icon" class="icon"></ImgDecypt>
            <div class="name">{{ item.name }}</div>
            <div class="install">安装</div>
          </div>
        </div>
        <div
          class="appItem"
          @click="jump(item)"
          v-for="item in shuApp"
          :key="item.id"
        >
          <div class="leftBox">
            <ImgDecypt
              :key="item.id"
              :src="item.icon"
              class="cover"
            ></ImgDecypt>
            <div class="appInfo">
              <div class="appName">
                {{ item.name }}
              </div>
              <div class="desc">
                {{ item.desc }}
              </div>
            </div>
          </div>
          <div class="open">打开</div>
        </div>
      </div>
    </div>

    <Loading v-else />
    <!-- <BottomAdvSwiper
      :advList="bottomAdvList"
      :hasPagination="true"
      v-if="bottomAdvList.length"
      class="bottomAdv adv"
    /> -->
  </div>
</template>
<script>
import { getHomeApps } from "@/api/home";
import { jumpAdvs } from "@/utils/getConfig";
import Loading from "@/components/Loading/index.vue";
import NoData from "@/components/NoData/index.vue";
import AdvSwiper from "@/components/AdvSwiper";
// import BottomAdvSwiper from "@/components/BottomAdvSwiper";
export default {
  name: "tabItem",
  components: {
    ImgDecypt: () => import("@/components/ImgDecypt"),
    Loading,
    NoData,
    AdvSwiper,
    // BottomAdvSwiper,
  },
  props: {
    moduleId: String,
    topAdvList: Array,
    bottomAdvList: Array,
  },
  data() {
    return {
      hengApp: [],
      shuApp: [],
      loading: true,
    };
  },
  created() {
    this.getApps();
    console.log(this.bottomAdvList, "----");
  },
  methods: {
    async getApps() {
      let res = await this.$Api(getHomeApps, this.moduleId);
      this.loading = false;
      if (res && res.code == 200) {
        this.hengApp = res.data.hengApp || [];
        this.shuApp = res.data.shuApp || [];
      }
    },
    jump(data) {
      jumpAdvs(data);
    },
  },
};
</script>
<style lang="scss" scoped>
.tabItem {
  padding: 15px 0 0;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  height: calc(100vh - 150px);
  .main {
    // content {
    //   padding: 10px 16px 0;
    // }
    padding-bottom: 240px;
    .appBoxs {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      grid-row-gap: 20px;
      grid-column-gap: 12px;
      margin-bottom: 50px;
      padding: 15px 16px 0;
      .appBox {
        width: 70px;
        .icon {
          height: 70px;
          width: 70px;
          border-radius: 8px;
          overflow: hidden;
        }
        .name {
          font-size: 13px;
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin: 10px 0 7px;
        }
        .install {
          height: 25px;
          width: 60px;
          border-radius: 30px;
          background: linear-gradient(to right, #ff8a48, #ff3d57);
          text-align: center;
          line-height: 25px;
          font-size: 12px;
          margin: 0 auto;
        }
      }
    }
    .appItem {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #5c5c5c30;
      margin: 20px 0;
      padding: 0 16px;
      .leftBox {
        display: flex;
        align-items: center;
        .cover {
          height: 60px;
          width: 60px;
          border-radius: 8px;
          overflow: hidden;
        }
        .appInfo {
          width: 250px;
          margin-left: 10px;
          .appName {
            font-size: 14px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .desc {
            font-size: 12px;
            color: #999fad;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin-top: 3px;
          }
        }
      }
      .open {
        height: 25px;
        width: 60px;
        border-radius: 30px;
        background: linear-gradient(to right, #ff8a48, #ff3d57);
        text-align: center;
        line-height: 25px;
        font-size: 12px;
      }
    }
  }
  .adv {
    height: 156px;
    :deep(img) {
      height: 156px;
    }
  }
  .bottomAdv {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
  }
}
</style>
