import axios from "axios";
import store from "@/store/index.js";
import CryptoJS from "crypto-js";
import {
  cleanAllLocal,
  cleanAllSession,
  getLocalItem,
  getSessionItem,
  removeLocal,
} from "@/utils/longStorage";
import { getDevType } from "@/utils/index";
import { Base64 } from "js-base64";
import { Dialog } from "vant";
import router from "@/router";

const Terminal = process.env.VUE_APP_BASE_Terminal; // 1-接口需要加密

const logAip = sessionStorage.getItem("logApi") + "/api/embed/prd/dataAdd";
// console.log(logAip)
//选线过后的api域名
const baseUrl = () => {
  return store?.getters?.baseUrl;
};
const getToken = () => {
  return getLocalItem("token") || "";
};
const getDevID = () => {
  return store?.getters?.UID || "";
};
const service = axios.create({
  baseURL: "https://d3bk5vmczrjvmf.cloudfront.net" || "", //
  // baseURL: "https://yr.cestalt.com" || "", //
  timeout: 1000 * 15, // request timeout
});
//重设接口超时时间
const restTimeout = (config) => {
  if (config.url == "vid/uploadDotJson") {
    config.timeout = 1000 * 120;
  }
};
service.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    if (baseUrl()) {
      config.baseURL = baseUrl();
    }
    if (getToken()) {
      config.headers["authorization"] = getToken();
    }
    //参数加密 get
    if (
      config.method === "get" &&
      Terminal == 1 &&
      config.params &&
      Object.keys(config.params).length > 0 &&
      config.url !==
        "https://ccdcapi.alipay.com/validateAndCacheCardInfo.json" &&
      config.url !== logAip
    ) {
      let data = config.params;
      //把所有参数改为字符串
      for (let key in data) {
        if (data[key] != null && data[key] != undefined) {
          data[key] = String(data[key]);
        } else {
          delete data[key];
        }
      }
      config.params = {
        data: encrypt(data),
      };
    }
    // console.log(config.url)
    // console.log(logAip)
    //参数加密 post
    if (
      config.method != "get" &&
      Terminal == 1 &&
      config.data &&
      Object.keys(config.data).length > 0 &&
      config.url !== logAip
    ) {
      config.data = {
        data: encrypt(config.data),
      };
    }

    //重设接口超时时间
    restTimeout(config);
    config.headers["temp"] = "test";
    config.headers["X-User-Agent"] =
      "BuildID=com.abc.Butterfly;SysType=" +
      getDevType() +
      ";DevID=" +
      getDevID() +
      `;Ver=1.0.0;DevType=iPhone;Terminal=${Terminal};IsH5=1`;
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    //如果加密了，需要先解密
    if (response.data.hash) {
      response.data.data = cryptoDecrypt(response.data.data);
    }
    if (response.data.code == 5009) {
      Dialog.alert({
        title: "提示",
        message: response?.data?.tip || "用户信息已过期",
        confirmButtonText: "确认",
        theme: "round-button",
      }).then(() => {
        cleanAllSession();
        removeLocal("token");
        router.go(0);
      });
      return response;
    }
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

export default service;

//参数加密
function encrypt(prop) {
  // 处理word参数 1.把请求字段和值转成json字符串
  let word = JSON.stringify(prop); //参数转成json字符串
  let eKey = "pa42en68iszeygvy"; //公钥加密后生成的私钥
  let iv = "pa42en68iszeygvy";
  iv = CryptoJS.enc.Utf8.parse(iv);
  let key = CryptoJS.enc.Utf8.parse(eKey);
  let srcs = CryptoJS.enc.Utf8.parse(word);
  let encrypted = CryptoJS.AES.encrypt(srcs, key, {
    iv,
    mode: CryptoJS.mode.CBC, //模式
    padding: CryptoJS.pad.Pkcs7,
  });
  return CryptoJS.enc.Base64.stringify(encrypted.ciphertext);
}

//接口解密
function cryptoDecrypt(data) {
  let dataStr = (data + "").replace(/\n*$/g, "").replace(/\n/g, "");
  //密钥
  let a = "dkV1a0EmdzE";
  let b = "1ejRWQUQza0FZI2ZrTCNy";
  let c = "Qm5VIVdEaE4=";
  let key = Base64.decode(a + b + c);

  let nonceLen = 12;
  let cipherBytes = Buffer.from(dataStr, "base64");
  let nonce = cipherBytes.slice(0, nonceLen);
  const keyBuf = new TextEncoder("utf-8").encode(key);
  let largeShaRaw = [...keyBuf, ...nonce];
  var wordArray = CryptoJS.enc.Hex.parse(Bytes2HexString(largeShaRaw));
  let largeShaRawMid = parseInt(largeShaRaw.length / 2);

  let msgKeyLargeBase64 = CryptoJS.SHA256(wordArray).toString(
    CryptoJS.enc.Base64
  );
  let msgKeyLargeUint8Bytes = Buffer.from(msgKeyLargeBase64, "base64");
  let msgKey = msgKeyLargeUint8Bytes.slice(8, 24);

  let shaRawA = [...msgKey, ...largeShaRaw.slice(0, largeShaRawMid)];
  let shaRawAWordArray = CryptoJS.enc.Hex.parse(Bytes2HexString(shaRawA));
  let sha256a = Buffer.from(
    CryptoJS.SHA256(shaRawAWordArray).toString(CryptoJS.enc.Base64),
    "base64"
  );

  let shaRawB = [...largeShaRaw.slice(largeShaRawMid), ...msgKey];
  let shaRawBWordArray = CryptoJS.enc.Hex.parse(Bytes2HexString(shaRawB));
  let sha256b = Buffer.from(
    CryptoJS.SHA256(shaRawBWordArray).toString(CryptoJS.enc.Base64),
    "base64"
  );

  let aesKey = [
    ...sha256a.slice(0, 8),
    ...sha256b.slice(8, 24),
    ...sha256a.slice(24),
  ];
  let aseKeyWordArray = CryptoJS.enc.Hex.parse(Bytes2HexString(aesKey));

  let aesIV = [
    ...sha256b.slice(0, 4),
    ...sha256a.slice(12, 20),
    ...sha256b.slice(28),
  ];
  let aseIvWordArray = CryptoJS.enc.Hex.parse(Bytes2HexString(aesIV));

  let hexData = Bytes2HexString(cipherBytes.slice(nonceLen));
  let base64Data = CryptoJS.enc.Hex.parse(hexData).toString(
    CryptoJS.enc.Base64
  );
  try {
    var decrypt = CryptoJS.AES.decrypt(base64Data, aseKeyWordArray, {
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
      iv: aseIvWordArray,
    }).toString(CryptoJS.enc.Utf8);
    return JSON.parse(decrypt);
  } catch (e) {
    console.log(e);
  }
}

//二进制数据转hex
function Bytes2HexString(arrBytes) {
  var str = "";
  for (var i = 0; i < arrBytes.length; i++) {
    var tmp;
    var num = arrBytes[i];
    if (num < 0) {
      //此处填坑，当byte因为符合位导致数值为负时候，需要对数据进行处理
      tmp = (255 + num + 1).toString(16);
    } else {
      tmp = num.toString(16);
    }
    if (tmp.length == 1) {
      tmp = "0" + tmp;
    }
    str += tmp;
  }
  return str;
}
